import React from 'react';

import Container from '@repo/ui/container';
import Icon from '@repo/ui/icon';

import { handleUseStrapiImageUrl } from 'helpers/strapi';

import { useTranslation } from 'hooks/use-translation';

import { styles } from '.';

type TProps = {
  lang: string;
  socials: { __typename: string; icon: string; url: string }[];
  company?: any;
  cookiePolicy: any;
};

const InfoSection = (props: TProps) => {
  const { lang, socials, company, cookiePolicy } = props;

  const { t } = useTranslation(lang);

  return (
    <div className={styles.container} aria-disabled="true">
      <Container>
        <div className="pt-12 pb-6 flex flex-col sm:flex-row gap-y-4 gap-x-8 text-xs text-white font-sans text-center items-center sm:items-start sm:text-left">
          <ul className="flex flex-col sm:items-start gap-y-1 items-center">
            <li className="font-bold">{company?.name}</li>
            <li className={styles.item}>
              {company?.address?.streetName} {company?.address?.streetNumber}
            </li>
            <li className={styles.item}>
              {company?.address?.postalCode} {company?.address?.city}
            </li>
            <li className={styles.item}>{company?.address?.country}</li>
          </ul>
          <ul className="flex flex-col items-start gap-y-1">
            <li className={styles.item}>
              <Icon accessor={'phone' as any} className="text-xs" />
              {company?.telephoneNumber}
            </li>
            <li className={styles.item}>
              <Icon accessor={'envelope' as any} className="text-xs" />
              {company?.emailAddress}
            </li>
          </ul>
        </div>
        <div className={styles.content}>
          <p className={styles.paragraph}>
            {t('label.custom.credits', { year: new Date().getFullYear() })}
          </p>
          <ul className={styles.list}>
            <li>
              <a
                target="_blank"
                href={handleUseStrapiImageUrl(cookiePolicy?.url)}
                rel="noreferrer"
              >
                {t('component.footer.label.cookiePolicy')}
              </a>
            </li>
            {socials?.map((social) => (
              <li key={social.icon}>
                <a
                  href={social.url}
                  target="_blank"
                  className={styles.icon}
                  aria-label={social.icon}
                  rel="noreferrer"
                >
                  <Icon type="brands" accessor={social.icon as any} />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </div>
  );
};

export default InfoSection;
