const styles = {
  container:
    'flex-row gap-x-4 sm:gap-x-6 items-center pl-2 pr-6 flex xl:hidden',

  image: '-translate-y-1 2xl:mr-6',

  sheet: {
    container: 'flex flex-col h-full bg-white',
    content: 'p-8 grow flex flex-col',

    head: '',
    body: 'flex flex-col pt-12 grow',
    foot: '',

    list: 'flex flex-col !items-start !space-x-0 gap-y-2',
  },

  list: 'flex flex-row items-center gap-x-5',
  button: 'bg-primary-300 hover:bg-primary-300/80 cursor-pointer',
};

export default styles;
