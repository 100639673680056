'use client';

import React, { useState } from 'react';

import Image from 'components/nextjs-fixes/image';
import Link from 'components/nextjs-fixes/link';

import { usePathname } from 'next/navigation';

import Icon from '@repo/ui/icon';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
} from '@repo/ui/navigation-menu';
import { Sheet, SheetContent, SheetTrigger } from '@repo/ui/sheet';

import { toClassName } from 'helpers/format';
import { handleUseStrapiImageUrl } from 'helpers/strapi';

import { useTranslation } from 'hooks/use-translation';

import { Button } from 'components/button';

import { styles } from '.';

type TProps = {
  logo: any;
  routes: { id: string; label: string; path: string }[];
  socials: { __typename: string; icon: string; url: string }[];
  lang: string;
};

const SideMenuSection = (props: TProps) => {
  const { logo, lang, routes, socials } = props;

  const [isOpen, setIsOpen] = useState(false);

  const pathname = usePathname();

  const { t } = useTranslation(lang);

  return (
    <div className={styles.container}>
      <Sheet open={isOpen} onOpenChange={setIsOpen}>
        <SheetTrigger aria-label="hamburger">
          <Icon accessor="bars" />
        </SheetTrigger>
        <SheetContent
          side="left"
          className="w-[90vw] !p-0 border-r border-black"
        >
          <div className={styles.sheet.container}>
            <div className={styles.sheet.content}>
              <div
                className={styles.sheet.head}
                onClick={() => setIsOpen(false)}
              >
                <Link href="/">
                  <Image
                    src={handleUseStrapiImageUrl(logo?.url ?? '')}
                    width={170}
                    height={50}
                    alt="logo"
                    className={styles.image}
                  />
                </Link>
              </div>

              <div className={styles.sheet.body}>
                <NavigationMenu className="!items-start !justify-start">
                  <NavigationMenuList className={styles.sheet.list}>
                    {routes.map((route) => (
                      <NavigationMenuItem
                        key={route.id}
                        onClick={() => setIsOpen(false)}
                      >
                        <Link href={route.path} legacyBehavior passHref>
                          <NavigationMenuLink
                            className={toClassName(
                              'font-sans',
                              pathname.includes(route.path) && 'opacity-20',
                            )}
                          >
                            {route.label}
                          </NavigationMenuLink>
                        </Link>
                      </NavigationMenuItem>
                    ))}
                    <NavigationMenuItem onClick={() => setIsOpen(false)}>
                      <Link href="/tiny-houses" legacyBehavior passHref>
                        <NavigationMenuLink
                          className={toClassName(
                            // navigationMenuTriggerStyle(),
                            '!text-sm !font-sans !text-secondary-500',
                            pathname.includes('/tiny-houses') && '!opacity-20',
                          )}
                        >
                          {t('component.navbar.label.tinyHouses')}
                        </NavigationMenuLink>
                      </Link>
                    </NavigationMenuItem>
                  </NavigationMenuList>
                </NavigationMenu>
              </div>

              <div className={styles.sheet.foot}>
                {/* <LanguageSection lang={lang} /> */}
              </div>
            </div>

            <div className="text-sm font-sans">
              <div className="bg-primary-500 p-8">
                <Link href={`/${lang}/contact`}>
                  <Button
                    className={styles.button}
                    onClick={() => setIsOpen(false)}
                  >
                    {t('component.footer.label.getInContact')}
                  </Button>
                </Link>
              </div>
              <div className="bg-stone p-8 text-white">
                <ul className={styles.list}>
                  {socials?.map((social) => (
                    <li key={social.icon}>
                      <a
                        href={social.url}
                        target="_blank"
                        // className={styles.icon}
                        aria-label={social.icon}
                      >
                        <Icon type="brands" accessor={social.icon as any} />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default SideMenuSection;
