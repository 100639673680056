'use client';

import React from 'react';

import { useParams, usePathname } from 'next/navigation';

import CtaSection from './_components/CtaSection';
import InfoSection from './_components/InfoSection';

import { styles } from '.';

type TProps = {
  socials: { __typename: string; icon: string; url: string }[];
  company: any;
  cookiePolicy: any;
};

const Footer = (props: TProps) => {
  const { socials, company, cookiePolicy } = props;

  const params = useParams();
  const pathname = usePathname();

  return (
    <div className={styles.container}>
      {pathname !== `/${params.lang}/contact` && (
        <CtaSection lang={params?.lang as string} />
      )}
      <InfoSection
        lang={params?.lang as string}
        socials={socials}
        company={company}
        cookiePolicy={cookiePolicy}
      />
    </div>
  );
};

export default Footer;
