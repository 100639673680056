import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu";
import { cva } from "class-variance-authority";
import { ChevronDown } from "lucide-react";
import * as React from "react";

import { toClassName } from "helpers/format";

const NavigationMenuPrimitive_: any = NavigationMenuPrimitive;

const NavigationMenu: any = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive_.Root>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive_.Root>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive_.Root
    ref={ref}
    className={toClassName(
      "relative z-10 flex max-w-max flex-1 items-center justify-center",
      className,
    )}
    {...props}
  >
    {children}
    <NavigationMenuViewport />
  </NavigationMenuPrimitive_.Root>
));
NavigationMenu.displayName = NavigationMenuPrimitive_.Root.displayName;

const NavigationMenuList: any = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive_.List>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive_.List>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive_.List
    ref={ref}
    className={toClassName(
      "group flex flex-1 list-none items-center justify-center space-x-1",
      className,
    )}
    {...props}
  />
));
NavigationMenuList.displayName = NavigationMenuPrimitive_.List.displayName;

const NavigationMenuItem: any = NavigationMenuPrimitive_.Item;

const navigationMenuTriggerStyle = cva(
  "group inline-flex h-10 w-max items-center justify-center rounded-md bg-white px-4 py-2 text-sm font-medium transition-colors hover:bg-neutral-100 hover:text-neutral-900 focus:bg-neutral-100 focus:text-neutral-900 focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-neutral-100/50 data-[state=open]:bg-neutral-100/50 dark:bg-neutral-950 dark:hover:bg-neutral-800 dark:hover:text-neutral-50 dark:focus:bg-neutral-800 dark:focus:text-neutral-50 dark:data-[active]:bg-neutral-800/50 dark:data-[state=open]:bg-neutral-800/50",
);

const NavigationMenuTrigger: any = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive_.Trigger>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive_.Trigger>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive_.Trigger
    ref={ref}
    className={toClassName(navigationMenuTriggerStyle(), "group", className)}
    {...props}
  >
    {children}
    {""}
    <ChevronDown
      className="relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
      aria-hidden="true"
    />
  </NavigationMenuPrimitive_.Trigger>
));
NavigationMenuTrigger.displayName = NavigationMenuPrimitive_.Trigger.displayName;

const NavigationMenuContent = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive_.Content>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive_.Content>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive_.Content
    ref={ref}
    className={toClassName(
      "left-0 top-0 w-full data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out data-[motion=from-end]:slide-in-from-right-52 data-[motion=from-start]:slide-in-from-left-52 data-[motion=to-end]:slide-out-to-right-52 data-[motion=to-start]:slide-out-to-left-52 md:absolute md:w-auto",
      className,
    )}
    {...props}
  />
));
NavigationMenuContent.displayName = NavigationMenuPrimitive_.Content.displayName;

const NavigationMenuLink: any = NavigationMenuPrimitive_.Link;

const NavigationMenuViewport = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive_.Viewport>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive_.Viewport>
>(({ className, ...props }, ref) => (
  <div className={toClassName("absolute left-0 top-full flex justify-center")}>
    <NavigationMenuPrimitive_.Viewport
      className={toClassName(
        "origin-top-center relative mt-1.5 h-[var(--radix-navigation-menu-viewport-height)] w-full overflow-hidden rounded-md border border-neutral-200 bg-white text-neutral-950 shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-90 md:w-[var(--radix-navigation-menu-viewport-width)] dark:border-neutral-800 dark:bg-neutral-950 dark:text-neutral-50",
        className,
      )}
      ref={ref}
      {...props}
    />
  </div>
));
NavigationMenuViewport.displayName =
  NavigationMenuPrimitive_.Viewport.displayName;

const NavigationMenuIndicator = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive_.Indicator>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive_.Indicator>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive_.Indicator
    ref={ref}
    className={toClassName(
      "top-full z-[1] flex h-1.5 items-end justify-center overflow-hidden data-[state=visible]:animate-in data-[state=hidden]:animate-out data-[state=hidden]:fade-out data-[state=visible]:fade-in",
      className,
    )}
    {...props}
  >
    <div className="relative top-[60%] h-2 w-2 rotate-45 rounded-tl-sm bg-neutral-200 shadow-md dark:bg-neutral-800" />
  </NavigationMenuPrimitive_.Indicator>
));
NavigationMenuIndicator.displayName =
  NavigationMenuPrimitive_.Indicator.displayName;

export {
  navigationMenuTriggerStyle,
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuContent,
  NavigationMenuTrigger,
  NavigationMenuLink,
  NavigationMenuIndicator,
  NavigationMenuViewport,
};
