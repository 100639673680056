'use client';

import React from 'react';

import Link from 'components/nextjs-fixes/link';

import { usePathname } from 'next/navigation';

import Container from '@repo/ui/container';
import Icon from '@repo/ui/icon';

import { useTranslation } from 'hooks/use-translation';

import { styles } from '.';

type TProps = {
  banner: {
    __typename: string;
    id: string;
    content: string;
    url: string;
    isExternal: boolean;
  };

  lang: string;
};

const Banner = (props: TProps) => {
  const { banner, lang } = props;

  const { t } = useTranslation(lang);
  const pathname = usePathname();

  if (pathname.includes('/tiny-houses')) {
    return;
  }

  const children = (
    <>
      <div className={styles.content}>
        <p>{banner?.content}</p>
        <button
          className="font-semibold group-hover:underline pl-2 flex flex-row items-center gap-x-1 min-w-24"
          aria-disabled="true"
        >
          {t('label.custom.readMore')}
          <Icon accessor="arrow-right" className="text-sm" size="12px" />
        </button>
      </div>
    </>
  );

  const handleGenerateComponent = () => {
    let result;

    if (banner?.url.startsWith('http') || banner?.url.startsWith('www')) {
      result = (
        <a
          href={banner?.url}
          target={banner?.isExternal ? '_blank' : ''}
          rel="noreferrer"
        >
          {children}
        </a>
      );
    }

    if (banner?.url.startsWith('/')) {
      result = <Link href={`/${lang}${banner?.url}`}>{children}</Link>;
    }

    return result;
  };

  if (banner === null) {
    return;
  }

  return (
    <div className={styles.container} aria-disabled="true">
      <Container>{handleGenerateComponent()}</Container>
    </div>
  );
};

export default Banner;
