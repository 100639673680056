import React from 'react';

import Link from 'components/nextjs-fixes/link';

import Container from '@repo/ui/container';

import { useTranslation } from 'hooks/use-translation';

import { Button } from 'components/button';

import { styles } from '.';

type TProps = {
  lang: string;
};

const CtaSection = (props: TProps) => {
  const { lang } = props;

  const { t } = useTranslation(lang);

  return (
    <div className={styles.container} aria-disabled="true">
      <Container>
        <div className={styles.content}>
          <h3 className={styles.title}>{t('component.footer.title.h3')}</h3>
          <p className={styles.description}>
            {t('component.footer.paragraph.description')}
          </p>

          <Link href={`/${lang}/contact`}>
            <Button className={styles.button}>
              {t('component.footer.label.getInContact')}
            </Button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default CtaSection;
