import { styles } from ".";

export type TProps = {
  children: any;
};

const Container = (props: TProps) => {
  const { children } = props;

  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Container;
