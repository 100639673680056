import { navigationMenuTriggerStyle } from '@repo/ui/navigation-menu';

import { toClassName } from 'helpers/format';

const styles = {
  container: '',

  primary: 'flex-row gap-x-4 sm:gap-x-6 items-center px-6 hidden xl:block',
  secondary: 'flex-row gap-x-4 sm:gap-x-6 items-center px-6 hidden lg:block',

  link: toClassName(
    navigationMenuTriggerStyle(),
    '!text-sm !font-sans hover:!cursor-pointer',
  ),
};

export default styles;
