'use client';

import React from 'react';

import Link from 'components/nextjs-fixes/link';

import { usePathname } from 'next/navigation';

import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from '@repo/ui/navigation-menu';

import { toClassName } from 'helpers/format';

import { useTranslation } from 'hooks/use-translation';

import { styles } from '.';

type TProps = {
  routes: { id: string; label: string; path: string }[];
  //
  lang: string;
};

const NavigationMenuSection = (props: TProps) => {
  const { routes, lang } = props;

  const pathname = usePathname();
  const { t } = useTranslation(lang);

  return (
    <div className={styles.container}>
      <NavigationMenu className={styles.primary}>
        <NavigationMenuList>
          {routes.map((route) => (
            <NavigationMenuItem key={route.id}>
              <Link href={route.path} legacyBehavior passHref>
                <NavigationMenuLink
                  className={toClassName(
                    styles.link,
                    pathname.includes(route.path) && '!opacity-20',
                  )}
                >
                  {route.label}
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>
          ))}
          <NavigationMenuItem>
            <Link href={`/${lang}/tiny-houses`} legacyBehavior passHref>
              <NavigationMenuLink
                aria-disabled="true"
                className={toClassName(
                  navigationMenuTriggerStyle(),
                  '!text-sm !font-sans !text-secondary-500',
                  pathname.includes('/tiny-houses') && '!opacity-20',
                )}
              >
                {t('component.navbar.label.tinyHouses')}
              </NavigationMenuLink>
            </Link>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
    </div>
  );
};

export default NavigationMenuSection;
