"use client";

import * as SheetPrimitive from "@radix-ui/react-dialog";
import { cva, type VariantProps } from "class-variance-authority";
import { toClassName } from "helpers/format";
import { X } from "lucide-react";
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

const SheetPrimitive_: any = SheetPrimitive;

const Sheet: any = SheetPrimitive_.Root;

const X_: any = X;

const SheetTrigger: any = SheetPrimitive_.Trigger;

const SheetClose: any = SheetPrimitive_.Close;

const SheetPortal: any = SheetPrimitive_.Portal;

const SheetOverlay: any = forwardRef<
  ElementRef<typeof SheetPrimitive_.Overlay>,
  ComponentPropsWithoutRef<typeof SheetPrimitive_.Overlay>
>(({ className, ...props }, ref) => (
  <SheetPrimitive_.Overlay
    className={toClassName(
      "fixed inset-0 z-50 bg-black/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className,
    )}
    {...props}
    ref={ref}
  />
));
SheetOverlay.displayName = SheetPrimitive_.Overlay.displayName;

const sheetVariants = cva(
  "fixed z-50 gap-4 bg-background p-6 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500",
  {
    variants: {
      side: {
        top: "inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top",
        bottom:
          "inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom",
        left: "inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm",
        right:
          "inset-y-0 right-0 h-full w-3/4  border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm",
      },
    },
    defaultVariants: {
      side: "right",
    },
  },
);

interface SheetContentProps
  extends ComponentPropsWithoutRef<typeof SheetPrimitive_.Content>,
    VariantProps<typeof sheetVariants> {}

const SheetContent: any = forwardRef<
  ElementRef<typeof SheetPrimitive_.Content>,
  SheetContentProps
>(({ side = "right", className, children, ...props }, ref) => (
  <SheetPortal>
    <SheetOverlay />
    <SheetPrimitive_.Content
      ref={ref}
      className={toClassName(sheetVariants({ side }), className)}
      {...props}
    >
      {children}
      <SheetTitle className="hidden" />
      <SheetPrimitive_.Close className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary">
        <X_ className="h-4 w-4" />
        <span className="sr-only">Close</span>
      </SheetPrimitive_.Close>
    </SheetPrimitive_.Content>
  </SheetPortal>
));
SheetContent.displayName = SheetPrimitive_.Content.displayName;

const SheetHeader: any = ({ className, ...props }: any) => (
  <div
    className={toClassName(
      "flex flex-col space-y-2 text-center sm:text-left",
      className,
    )}
    {...props}
  />
);
SheetHeader.displayName = "SheetHeader";

const SheetFooter: any = ({ className, ...props }: any) => (
  <div
    className={toClassName(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
      className,
    )}
    {...props}
  />
);
SheetFooter.displayName = "SheetFooter";

const SheetTitle: any = forwardRef<
  ElementRef<typeof SheetPrimitive_.Title>,
  ComponentPropsWithoutRef<typeof SheetPrimitive_.Title>
>(({ className, ...props }, ref) => (
  <SheetPrimitive_.Title
    ref={ref}
    className={toClassName("text-lg font-semibold text-foreground", className)}
    {...props}
  />
));
SheetTitle.displayName = SheetPrimitive_.Title.displayName;

const SheetDescription: any = forwardRef<
  ElementRef<typeof SheetPrimitive_.Description>,
  ComponentPropsWithoutRef<typeof SheetPrimitive_.Description>
>(({ className, ...props }, ref) => (
  <SheetPrimitive_.Description
    ref={ref}
    className={toClassName("text-sm text-muted-foreground", className)}
    {...props}
  />
));
SheetDescription.displayName = SheetPrimitive_.Description.displayName;

export {
  Sheet,
  SheetPortal,
  SheetOverlay,
  SheetTrigger,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetFooter,
  SheetTitle,
  SheetDescription,
};
